import React from "react";
import styles from "./videoSection.module.scss";
import ReactPlayer from "react-player";
import { useState } from "react";

const VideoSection = () => {
  const [loading, setLoading] = useState(false);

  return (
    <div className={styles.container}>
      <p data-aos="zoom-out-up">QUICK START GUIDE</p>

      <div className={styles.wrapper}>
        <div
          className={styles.leftsection}
          data-aos="fade-up-left"
          data-aos-easing="ease-in-sine"
          data-aos-duration="700"
        >
          <div>
            <label>How to open an account:</label>
            <div className={styles.playerwrapper}>
              <video width="100%" height="100%" controls poster="/thumb1.jpg">
                <source
                  src="https://d1brp5cmiud168.cloudfront.net/videos/Wallet+Opening+Process+v2+20222212.mp4"
                  type="video/mp4"
                />
              </video>
              <span>
                This Video demonstrate how to open your account and how to fund
                your vault using PayPal.
              </span>
              {/* <ReactPlayer
                url={
                  "https://d1brp5cmiud168.cloudfront.net/videos/walletopen.mp4"
                }
                className={styles.player}
                light="/thumb1.jpg"
                width="100%"
                height="300px"
                playing
                controls
              /> */}
            </div>
          </div>
        </div>
        {/* <img src={"/logo.svg"} alt="logo" data-aos="zoom-out-up" /> */}
        {/* <div
          className={styles.rightsection}
          data-aos="fade-up-right"
          // data-aos-offset="100"
          data-aos-easing="ease-in-sine"
          data-aos-duration="700"
        >
          <div>
            <label>Funding With a Direct Wire:</label>
            <div className={styles.playerwrapper}>
              <ReactPlayer
                url={
                  "https://d1brp5cmiud168.cloudfront.net/videos/accountfunding.mp4"
                }
                light="/thumb2.jpg"
                width="100%"
                height="300px"
                playing
                controls
              />
              <video width="100%" height="100%" controls poster="/thumb2.jpg">
                <source
                  src="https://d1brp5cmiud168.cloudfront.net/videos/Account+Funding+(Direct+Wire)+v2+20222212.mp4"
                  type="video/mp4"
                />
              </video>
              <span>
                This Video demonstrate how to fund your vault using a direct
                wire.
              </span>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default VideoSection;
