import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import chatBlue from "../../../../../Assets/icons/chatbluedash.svg";
import chatWhite from "../../../../../Assets/icons/chatwhitedash.svg";
import logOutActive from "../../../../../Assets/icons/logOutBlue.svg";
import RammitanceBlue from "../../../../../Assets/icons/remitanceblue.svg";
import RammitanceWhite from "../../../../../Assets/icons/remitancewhite.svg";
import dashboard from "../../../../../Assets/image/dashboardInact.svg";
import dashboardActive from "../../../../../Assets/image/dashboardblue.svg";
import logo from "../../../../../Assets/image/logo.svg";
import Logout from "../../../../../Assets/image/logout.svg";
import MarketActive from "../../../../../Assets/image/marketactive.svg";
import Market from "../../../../../Assets/image/marketinact.svg";
import Nexticon from "../../../../../Assets/image/nextbutton.svg";
import PrivacyActive from "../../../../../Assets/image/privacybluedash.svg";
import Privacy from "../../../../../Assets/image/privacywhite.svg";
import profileActive from "../../../../../Assets/image/profilebluedashborad.svg";
import settings from "../../../../../Assets/image/settingswhite.svg";
import subscriptionActive from "../../../../../Assets/image/subscriptionactiv.svg";
import subscription from "../../../../../Assets/image/subsucinact.svg";
import Terms from "../../../../../Assets/image/terminact.svg";
import TermsActive from "../../../../../Assets/image/termsActive.svg";
import { getCurrencyDetail } from "../../../../../redux/action/currencyDetailAction";
import Popup from "../../../../_Common/DashboardDone/popup";
import Modal from "../../../../_Common/Modal/Modal";
import styles from "./sidebar.module.scss";
import Teamprofile from "../../../../../Assets/image/teamprofile.png";
import editprofile from "../../../../../Assets/image/editprofile.svg";
import loaderImg from "../../../../../Assets/icons/loadingblue.svg";
import $ from "jquery";
import { MdScreenShare } from "react-icons/md";
import { FaFileSignature } from "react-icons/fa";

import {
  imgUpload,
  imgUploadviaUserInfo,
  userCleanUp,
} from "../../../../../redux/action/userAction";
const SideBar = ({
  mobileView,
  id,
  handleMenu,
  history,
  visible,
  onClose,
  children,
  btn,
}) => {
  const [active, setActive] = useState(0);
  const [logout, setlogout] = useState(false);
  const [userImg, setUserImg] = useState("");
  const dispatch = useDispatch();
  const userSelector = useSelector((state) => state.user);
  const currencySelector = useSelector((state) => state.currency);

  const router = useLocation();

  const url =
    // "https://res.cloudinary.com/lazydev/image/upload/v1643880680/PrivacyPolicy.pdf";
    "https://transfer.app.box.com/s/04tyfkj0ev77t4495jnsa694yygy2cpz";
  const url3 =
    // "https://res.cloudinary.com/lazydev/image/upload/v1643880680/PrivacyPolicy.pdf";
    "https://transfer.app.box.com/s/rjp9inytqya2vhtw7sd4x599rf0yclzb";
  const url2 =
    // "https://res.cloudinary.com/lazydev/image/upload/v1643880680/Terms_Conditions.pdf";
    "https://transfer.app.box.com/s/xm2jsd5di6w8e82oj3z88jv3rwy9gv2u";

  const handleImg = (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("type", "profilePicture");
    dispatch(imgUpload(formData));
    dispatch(userCleanUp());
  };

  useEffect(() => {
    $('img[alt="Chat-Icon"]').show();

    if (userSelector.imgSuccess === "retrived successfully") {
      let imgurl = userSelector.img.url;
      let data = {
        profilePicture: imgurl,
      };
      dispatch(imgUploadviaUserInfo(data));
    }
    dispatch(userCleanUp());
  }, [userSelector.imgSuccess]);

  useEffect(() => {
    if (userSelector.imgUpdateSuccess === "successfully") {
      setUserImg(userSelector.imgUpdateUserInfo);
      dispatch(getCurrencyDetail());
    }
    dispatch(userCleanUp());
  }, [userSelector.imgUpdateSuccess]);

  useEffect(() => {}, [userSelector, currencySelector]);

  useEffect(() => {
    if (id === "cryptocurrency" || id === "cqr-vault" || id === "cqr-vest") {
      setActive(0);
    } else if (id === "settings") {
      setActive(1);
    } else if (id === "profile") {
      setActive(2);
    } else if (id === "history") {
      setActive(3);
    } else if (id === "terms-and-conditions") {
      setActive(4);
    } else if (id === "privacy-and-policy") {
      setActive(5);
    } else if (id === "subscription") {
      setActive(6);
    } else if (id === "chat") {
      setActive(7);
    } else if (id === "remittance") {
      setActive(8);
    } else if (id === "marketplace") {
      setActive(9);
    } else if (id === "inbox") {
      setActive(10);
    } else if (id === "shared-expenses") {
      setActive(11);
    }
  }, [active, id]);
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {!mobileView && (
          <Link to="/">
            <div className={styles.logo}>
              <img src={logo} alt="" />
            </div>
          </Link>
        )}
        {mobileView && (
          <div className={styles.profilediv}>
            <label For="inputimage">
              <div className={styles.profile}>
                {userSelector.imgLoader ? (
                  <img className={styles.img1} src={loaderImg} alt="" />
                ) : (
                  <img
                    className={styles.img2}
                    src={
                      userSelector?.userInfo?.profilePicture
                        ? userSelector?.userInfo?.profilePicture +
                          "?" +
                          new Date()
                        : Teamprofile
                    }
                    alt="profile"
                  />
                )}

                <div className={styles.editprofile}>
                  <div className={styles.selectimage}>
                    <img src={editprofile} />
                  </div>
                  <input
                    type="file"
                    multiple
                    accept="image/png, image/gif, image/jpeg"
                    onChange={(e) => handleImg(e)}
                    id="inputimage"
                  />
                </div>
              </div>
            </label>
          </div>
        )}
        {/* <div className={styles.profilediv}>
          <label For="inputimage">
            <div className={styles.profile}>
              {userSelector.imgLoader ? (
                <img className={styles.img1} src={loaderImg} alt="" />
              ) : (
                <img
                  className={styles.img2}
                  src={
                    currencySelector?.currencyInfo?.user?.profilePicture
                      ? currencySelector?.currencyInfo?.user?.profilePicture +
                        "?" +
                        new Date()
                      : Teamprofile
                  }
                  alt="profile"
                />
              )}

              <div className={styles.editprofile}>
                <div className={styles.selectimage}>
                  <img src={editprofile} />
                </div>
                <input
                  type="file"
                  multiple
                  accept="image/png, image/gif, image/jpeg"
                  onChange={(e) => handleImg(e)}
                  id="inputimage"
                />
              </div>
            </div>
          </label>
          <div className={styles.showmail}>
            <div
              className={styles.textElllip}
              onClick={() => history.push("/settings")}
            >
              {currencySelector?.currencyInfo?.user?.name}
            </div>
            <span>{currencySelector?.currencyInfo?.user?.email}</span>
          </div>
        </div> */}
        <div
          onClick={() => history.push("/cryptocurrency")}
          className={`${
            id === "cryptocurrency" ? styles.activeLink : styles.tab
          } `}
        >
          <div className={styles.icon}>
            <img src={dashboard} alt="Dashboard" />
          </div>
          <div className={styles.iconActive}>
            <img src={dashboardActive} alt="Dashboard" />
          </div>
          {mobileView ? (
            <div className={`${styles.text} `} onClick={() => handleMenu()}>
              Dashboard
              {/* <img src={Downicon} alt="down" /> */}
            </div>
          ) : (
            <>
              <div className={`${styles.text} `} onClick={() => setActive()}>
                Dashboard
              </div>
              <img src={Nexticon} className={styles.imgNext} alt="" />
            </>
          )}
        </div>
        <Link
          to="/marketplace"
          onClick={() => history.push("/subscription")}
          className={`${
            id === "marketplace" ? styles.activeLink : styles.tab
          } `}
        >
          <div className={styles.icon}>
            <img src={Market} alt="subscription" />
          </div>
          <div className={styles.iconActive}>
            <img src={MarketActive} alt="market" />
          </div>
          {mobileView ? (
            <div
              className={styles.text}
              onClick={() => {
                handleMenu();
                // setActive(6);
              }}
            >
              CQR Vest
            </div>
          ) : (
            <>
              <div
                className={styles.text}
                onClick={() => {
                  // handleMenu();
                  setActive(9);
                }}
              >
                CQR Vest
              </div>
              <img src={Nexticon} className={styles.imgNext} alt="" />
            </>
          )}
        </Link>
        <Link
          to="/inbox"
          onClick={() => history.push("/inbox")}
          className={`${id === "inbox" ? styles.activeLink : styles.tab} `}
        >
          <div className={styles.icon}>
            <img src={chatWhite} alt="Privacy" />
          </div>
          <div className={styles.iconActive}>
            <img src={chatBlue} alt="Privacy" />
          </div>
          {mobileView ? (
            <div
              className={styles.text}
              onClick={() => {
                handleMenu();
                // setActive(5);
              }}
            >
              CQR Connect
            </div>
          ) : (
            <>
              <div
                className={styles.text}
                onClick={() => {
                  // handleMenu();
                  setActive(10);
                }}
              >
                CQR Connect
              </div>
              <img src={Nexticon} className={styles.imgNext} alt="" />
            </>
          )}
        </Link>
        {/* {active === 0 && (
          <Link
            to="/cryptocurrency"
            onClick={() => history.push("/cryptocurrency")}
            className={`${
              id === "cryptocurrency" ? styles.activeLink : styles.tab
            } `}
          >
            {mobileView ? (
              <div
                className={styles.text}
                onClick={() => {
                  handleMenu();
                  // setActive(0);
                }}
              >
                Cryptocurrency
              </div>
            ) : (
              <div
                className={styles.text}
                onClick={() => {
                  // handleMenu();
                  setActive(0);
                }}
              >
                Cryptocurrency
              </div>
            )}
          </Link>
        )} */}
        {/* {active === 0 && (
          <Link
            to="/cqr-vault"
            onClick={() => history.push("/cqr-vault")}
            className={`${
              id === "cqr-vault" ? styles.activeLink : styles.tab
            } `}
          >
            {mobileView ? (
              <div
                className={styles.text}
                onClick={() => {
                  handleMenu();
                  // setActive(0);
                }}
              >
                C<CQRComponent /> */}
        {/* {id === "cqr-vault" ? (
                <img className={styles.Qimg} src={blue} />
              ) : (
                <img className={styles.Qimg} src={white} />
              )} */}
        {/* R Vault
              </div>
            ) : (
              <div
                className={styles.text}
                onClick={() => {
                  // handleMenu();
                  setActive(0);
                }}
              >
                C<CQRComponent /> */}
        {/* {id === "cqr-vault" ? (
                 <img className={styles.Qimg} src={blue} />
               ) : (
                 <img className={styles.Qimg} src={white} />
               )} */}
        {/* R Vault
              </div>
            )}
          </Link>
        )} */}

        {/* {active === 0 && (
          <Link
            to="/cqr-vest"
            onClick={() => history.push("/cqr-vest")}
            className={`${id === "cqr-vest" ? styles.activeLink : styles.tab} `}
            style={{ borderBottom: "1px solid #2d3141" }}
          >
            {mobileView ? (
              <div
                className={styles.text2}
                onClick={() => {
                  handleMenu();
                  // setActive(0);
                }}
              >
                C<CQRComponent /> */}
        {/* {id === "cqr-vest" ? (
                <img className={styles.Qimg} src={blue} />
              ) : (
                <img className={styles.Qimg} src={white} />
              )} */}
        {/* R Vest
              </div>
            ) : (
              <div
                className={styles.text2}
                onClick={() => {
                  // handleMenu();
                  setActive(0);
                }}
              >
                C<CQRComponent /> */}
        {/* {id === "cqr-vest" ? (
                <img className={styles.Qimg} src={blue} />
              ) : (
                <img className={styles.Qimg} src={white} />
              )} */}
        {/* R Vest
              </div>
            )}
          </Link>
        )} */}
        <Link
          to="/settings"
          onClick={() => history.push("/settings")}
          className={`${id === "settings" ? styles.activeLink : styles.tab} `}
        >
          <div className={styles.icon}>
            <img src={settings} alt="Settings" />
          </div>
          <div className={styles.iconActive}>
            <img src={profileActive} alt="Profile" />
          </div>
          {mobileView ? (
            <div
              className={styles.text}
              onClick={() => {
                handleMenu();
                // setActive(1);
              }}
            >
              Account Info
            </div>
          ) : (
            <>
              <div
                className={styles.text}
                onClick={() => {
                  // handleMenu();
                  setActive(1);
                }}
              >
                Account Info
              </div>
              <img src={Nexticon} className={styles.imgNext} alt="" />
            </>
          )}
        </Link>

        {/* Ramittance */}
        <Link
          to="/remittance"
          onClick={() => history.push("/remittance")}
          className={`${id === "remittance" ? styles.activeLink : styles.tab} `}
        >
          <div className={styles.icon}>
            <img src={RammitanceWhite} alt="remittance" />
          </div>
          <div className={styles.iconActive}>
            <img src={RammitanceBlue} alt="remittance" />
          </div>
          {mobileView ? (
            <div
              className={styles.text}
              onClick={() => {
                handleMenu();
                // setActive(1);
              }}
            >
              Remittance
            </div>
          ) : (
            <>
              <div
                className={styles.text}
                onClick={() => {
                  // handleMenu();
                  setActive(8);
                }}
              >
                Remittance
              </div>
              <img src={Nexticon} className={styles.imgNext} alt="" />
            </>
          )}
        </Link>
        {/* Ramittance end */}
        <Link
          to="/profile"
          onClick={() => history.push("/profile")}
          className={`${id === "profile" ? styles.activeLink : styles.tab} `}
        >
          <div className={styles.icon}>
            <img src={settings} alt="Settings" />
          </div>
          <div className={styles.iconActive}>
            <img src={profileActive} alt="Profile" />
          </div>
          {mobileView ? (
            <div
              className={styles.text}
              onClick={() => {
                handleMenu();
                // setActive(2);
              }}
            >
              Profile
            </div>
          ) : (
            <>
              <div
                className={styles.text}
                onClick={() => {
                  // handleMenu();
                  setActive(2);
                }}
              >
                Profile
              </div>
              <img src={Nexticon} className={styles.imgNext} alt="" />
            </>
          )}
        </Link>

        {/* <div
          onClick={() => history.push("/history")}
          className={`${id === "history" ? styles.activeLink : styles.tab} `}
        >
          <div className={styles.icon}>
            <img src={History} alt="history" />
          </div>
          <div className={styles.iconActive}>
            <img src={historyActive} alt="history" />
          </div>
          <div className={styles.text} onClick={() => setActive(3)}>
            History
          </div>
        </div> */}

        <Link
          to="/subscription"
          onClick={() => history.push("/subscription")}
          className={`${
            id === "subscription" ? styles.activeLink : styles.tab
          } `}
        >
          <div className={styles.icon}>
            <img src={subscription} alt="subscription" />
          </div>
          <div className={styles.iconActive}>
            <img src={subscriptionActive} alt="subscription" />
          </div>
          {mobileView ? (
            <div
              className={styles.text}
              onClick={() => {
                handleMenu();
                // setActive(6);
              }}
            >
              Subscription
            </div>
          ) : (
            <>
              <div
                className={styles.text}
                onClick={() => {
                  // handleMenu();
                  setActive(6);
                }}
              >
                Subscription
              </div>
              <img src={Nexticon} className={styles.imgNext} alt="" />
            </>
          )}
        </Link>
        <a
          href={url3}
          rel="noreferrer"
          target="_blank"
          className={`${
            id === "privacy-and-policy" ? styles.activeLink : styles.tab
          } `}
        >
          <div className={styles.icon}>
            <FaFileSignature />

            {/* <img src={Privacy} alt="Privacy" /> */}
          </div>
          <div className={styles.iconActive}>
            <FaFileSignature />

            {/* <img src={PrivacyActive} alt="Privacy" /> */}
          </div>
          {mobileView ? (
            <div
              className={styles.text}
              onClick={() => {
                handleMenu();
                // setActive(5);
              }}
            >
              Tutorials
            </div>
          ) : (
            <>
              <div
                className={styles.text}
                onClick={() => {
                  // handleMenu();
                  setActive(12);
                }}
              >
                Tutorials
              </div>
              <img src={Nexticon} className={styles.imgNext} alt="" />
            </>
          )}
        </a>
        <Link
          to="/chat"
          onClick={() => history.push("/chat")}
          className={`${id === "chat" ? styles.activeLink : styles.tab} `}
        >
          <div className={styles.icon}>
            <img src={chatWhite} alt="" />
          </div>
          <div className={styles.iconActive}>
            <img src={chatBlue} alt="" />
          </div>
          {mobileView ? (
            <div
              className={styles.text}
              onClick={() => {
                handleMenu();
                // setActive(7);
              }}
            >
              Chat
            </div>
          ) : (
            <>
              <div
                className={styles.text}
                onClick={() => {
                  // handleMenu();
                  setActive(7);
                }}
              >
                Support
              </div>
              <img src={Nexticon} className={styles.imgNext} alt="" />
            </>
          )}
        </Link>
        <a
          href={url}
          rel="noreferrer"
          target="_blank"
          className={`${
            id === "privacy-and-policy" ? styles.activeLink : styles.tab
          } `}
        >
          <div className={styles.icon}>
            <img src={Privacy} alt="Privacy" />
          </div>
          <div className={styles.iconActive}>
            <img src={PrivacyActive} alt="Privacy" />
          </div>
          {mobileView ? (
            <div
              className={styles.text}
              onClick={() => {
                handleMenu();
                // setActive(5);
              }}
            >
              Privacy Policy
            </div>
          ) : (
            <>
              <div
                className={styles.text}
                onClick={() => {
                  // handleMenu();
                  setActive(5);
                }}
              >
                Privacy Policy
              </div>
              <img src={Nexticon} className={styles.imgNext} alt="" />
            </>
          )}
        </a>
        <a
          href="https://transfer.app.box.com/s/01uag7rtcf6ujmkqnwlqu6bnupreanv3"
          rel="noreferrer"
          target="_blank"
          className={`${
            id === "shared-expenses" ? styles.activeLink : styles.tab
          } `}
        >
          <div className={styles.icon}>
            <MdScreenShare color="#A7A7A7" fontSize={20} />
          </div>
          <div className={styles.iconActive}>
            <MdScreenShare color="#40A1DC" fontSize={20} />
          </div>
          {mobileView ? (
            <div
              className={styles.text}
              onClick={() => {
                handleMenu();
                // setActive(5);
              }}
            >
              Shared Expenses
            </div>
          ) : (
            <>
              <div
                className={styles.text}
                onClick={() => {
                  // handleMenu();
                  setActive(11);
                }}
              >
                Shared Expenses
              </div>
              <img src={Nexticon} className={styles.imgNext} alt="" />
            </>
          )}
        </a>
        {/* <div
          onClick={() => history.push("/dashboard/privacy-and-policy")}
          className={`${
            id === "privacy-and-policy" ? styles.activeLink : styles.tab
          } `}
        >
          <div className={styles.icon}>
            <img src={Privacy} alt="Privacy" />
          </div>
          <div className={styles.iconActive}>
            <img src={PrivacyActive} alt="Privacy" />
          </div>
          <div className={styles.text} onClick={() => setActive(3)}>
            Privacy Policy
          </div>
        </div> */}
        <a
          href={url2}
          rel="noreferrer"
          target="_blank"
          className={`${
            id === "terms-and-conditions" ? styles.activeLink : styles.tab
          } `}
        >
          <div className={styles.icon}>
            <img src={Terms} alt="Terms" />
          </div>
          <div className={styles.iconActive}>
            <img src={TermsActive} alt="Terms" />
          </div>
          {mobileView ? (
            <div
              className={styles.text}
              onClick={() => {
                handleMenu();
                // setActive(4);
              }}
            >
              Terms & Conditions
            </div>
          ) : (
            <>
              <div
                className={styles.text}
                onClick={() => {
                  // handleMenu();
                  setActive(4);
                }}
              >
                Terms & Conditions
              </div>
              <img src={Nexticon} className={styles.imgNext} alt="" />
            </>
          )}
        </a>
        <div
          className={`${
            router.pathname === "/" ? styles.activeLink : styles.tab
          } `}
          onClick={() => {
            setlogout(true);
          }}
        >
          <div className={styles.icon}>
            <img src={Logout} alt="Logout" />
          </div>
          <div className={styles.iconActive}>
            <img src={logOutActive} alt="Logout" />
          </div>
          <div className={styles.text}>Logout</div>
        </div>
        {/* <div
          onClick={() => history.push("/dashboard/terms-and-conditions")}
          className={`${
            id === "terms-and-conditions" ? styles.activeLink : styles.tab
          } `}
        >
          <div className={styles.icon}>
            <img src={Terms} alt="Terms" />
          </div>
          <div className={styles.iconActive}>
            <img src={TermsActive} alt="Terms" />
          </div>
          <div className={styles.text} onClick={() => setActive(4)}>
            Terms & Conditions
          </div>
        </div> */}
      </div>
      <Modal visible={logout} onClose={() => setlogout(false)}>
        <Popup
          src={logo}
          para="Are you sure you want to logout?"
          btn="Yes"
          btn1="Cancel"
          BtnShow
          // onPressButton={() => history.push("/")}
          onPressinnerButton={() => setlogout(false)}
        />
      </Modal>
    </div>
  );
};
export default SideBar;
